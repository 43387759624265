import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

const ButtonStyle = styled.button`
  cursor: pointer;
  background: #e53d3d;
  border-color: #d3027d;
  color: #fff;
  text-transform: uppercase;
  border: none;
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 2px;
  border-radius: unset;

  &:hover {
    transition: 0.3s all;
    background: #bf1650;
  }

  &:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
`

export const Button: FunctionComponent<{
  label: string
  ctaHref: string
}> = props => {
  const { label, ctaHref } = props
  return (
    <Link passHref href={ctaHref}>
      <ButtonStyle>{label}</ButtonStyle>
    </Link>
  )
}
