import { FunctionComponent } from 'react'
import Image from 'next/image'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TopRow = styled.div`
  margin-top: 30px;
`
const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`

export const LogoSection: FunctionComponent = () => {
  return (
    <Container>
      <TopRow>
        <Image src='/images/pdx.webp' alt='PDX Products Logo' width={338} height={43} />
      </TopRow>
    </Container>
  )
}
